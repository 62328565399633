import React, {useState} from "react";
import { Heading } from "@abyss/web/ui/Heading/Heading";
import { Text } from "@abyss/web/ui/Text";
import { TextInput } from "@abyss/web/ui/TextInput";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useForm } from "@abyss/web/hooks/useForm/useForm";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "@abyss/web/ui/FormProvider";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { TextInputArea } from "@abyss/web/ui/TextInputArea";
import axios from "axios";
import { StyledGrid, StyledGridCol,StyledButton } from "../utils/StyledComponents";
import { useToast } from "@abyss/web/hooks/useToast";
import PrintButton from "../utils/PrintButton";
import {
  alphaNumericWithdecimal,
  alphaNumericWithoutSpace,
  alphaValidation
} from "../utils/ValidationPatterns";
import DocUpload from "../utils/DocUpload";
import LoadingSpinner from "../utils/LoadingSpinner";

const IndianaHospiceForm = () => {
  useDocumentTitle("Indiana Hospice Form");
  const navigate = useNavigate();
  const { toast } = useToast();
  const defaultValues = {
    providerName: "",
    providerPhoneNumber: "",
    memberFullName: "",
    memberMedicaidId: "",
    diagnosisCodes: " ",
    additionalComments: " ",
    files: [],
  };

  const INDIANA_HOSPICE_FORM_API = "/api/indianaHospiceNotificationForm";
  const form = useForm(defaultValues);
  const  [isLoading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const resp = await axios.post(INDIANA_HOSPICE_FORM_API, {...data,diagnosisCodes:data.diagnosisCodes || " ", additionalComments: data.additionalComments || " " }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (resp.status === 200) {
        setLoading(false);
        toast.show({
          id: "success-toast",
          autoClose: 5000,
          withCloseButton: true,
          title: "Success",
          icon: <IconMaterial icon="check_circle" />,
          css: { "abyss-toast-container": { backgroundColor: "$success1" } },
          message:"Mail Sent Successfully!",
        });
        navigate("/indianaHospiceConfirmation", {
          state: { data: { ...data, submissionId: resp.data.submissionId, submittedDate: resp.data.submittedDate } },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    toast.show({
      id: "error-toast",
      autoClose:5000,
      withCloseButton: true,
      title: "Error",
      message:error?.response?.data?.detail || " Something went wrong. Please try again later.",
      icon: <IconMaterial icon="error" />,
      css: { "abyss-toast-container": { backgroundColor: "$error3" } },
    })
    }
  };

  return (
    <LoadingSpinner
    isLoading={isLoading}>
    <FormProvider state={form} onSubmit={onSubmit}>
      <StyledGrid container>
        <StyledGrid section>
          <StyledGridCol span={11} rowGapSm paddingYZero>
            <Heading offset={2}>
              Indiana Hospice Notification form
            </Heading>
            <Text size="$sm">
              UnitedHealthcare requests notification of hospice services including home and inpatient hospice. Submit the Hospice election form, Physician certification, and Hospice plan of care here.
            </Text>
          </StyledGridCol>
          <PrintButton />
        </StyledGrid>

        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Provider contact information</Heading>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Name"
              model="providerName"
              isClearable
              maxLength={95}
              validators={{
                required: "Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Name should not contain numeric or special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              mask="phone"
              label="Phone"
              model="providerPhoneNumber"
              isClearable
              inputLeftElement={
                <IconMaterial icon="phone" size="18px" color="$gray6" />
              }
              validators={{
                required: "Phone is required",
                validate: (v) => {
                  const value = v;
                  const phoneNumber = value.replace(/\D/g, "");
                  if (phoneNumber && phoneNumber.length < 10) {
                    return "Phone should have 10 digits";
                  }
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
        </StyledGrid>

        <StyledGrid section>
          <StyledGridCol paddingYZero span={12}>
            <Heading offset={4}>Member Information</Heading>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Full Name"
              model="memberFullName"
              isClearable
              maxLength={95}
              validators={{
                required: "Full Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Full Name should not contain numeric or special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Medicaid ID"
              model="memberMedicaidId"
              maxLength={30}
              isClearable
              validators={{
                required: "Medicaid ID is required",
                pattern: {
                  value: alphaNumericWithoutSpace,
                  message: "Medicaid ID should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          ></StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          ></StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            paddingYZero>
            <TextInput
              label="Diagnosis code(s)"
              model="diagnosisCodes"
              maxLength={10}
              isClearable
              width="100%"
              validators={{
                pattern: {
                  value: alphaNumericWithdecimal,
                  message: "Diagnosis code(s) should not contain special characters",
                },
              }}>
            </TextInput>
          </StyledGridCol>
        </StyledGrid>

        <StyledGrid section>
          <StyledGridCol paddingYZero span={12}>
            <Heading offset={4}>Additional comments</Heading>
          </StyledGridCol>
          <StyledGridCol
            span={12}
            paddingYZero
          >
            <TextInputArea
              style={{ marginTop: "12px" }}
              label="Comments"
              maxLength={200}
              model="additionalComments"
              displayMaxLength
              disableAutoSize
              descriptorsDisplay="column"
            />
          </StyledGridCol>
        </StyledGrid>

        <DocUpload />
        <StyledGridCol style={{width:'100px'}} paddingYZero>
        <StyledButton type='submit' >
          Submit
        </StyledButton>
        </StyledGridCol>
     
      </StyledGrid>
    </FormProvider>
    </LoadingSpinner>
  );
};

export default IndianaHospiceForm;